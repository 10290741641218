import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BASE_API } from "services/config";

import prepareHeaders from "./common";

const routeRoot = 'counters/';

export interface IStore {
  id: number;
  uuid: string;
  name: string;
  address: string;
}

export interface IDevice {
  id: number;
  uuid: string;
  name: string;
  ipAddress: string;
  macAddress: string;
  latitude: string;
  longitude: string;
  notes: string;
  mqttTopic?: string;
  deviceType?: number;
}

export interface IReadings {
  id: number;
  idStore: number;
  dataName: string;
  dataValue: number;
  readingAt: Date;
}

export interface ICounters {
  id: number;
  idDevice: number;
  dataField: string;
  counterValue: number;
  createdOn: Date;
  updatedOn: Date;
  reset: boolean;
  active: boolean;
}

export interface ICountersGrid {
  id: number;
  Field: string;
  Counter:string;
  CreatedOn:string;
  Active:boolean;
  Managed:boolean;
}


const counterApi = createApi({
  tagTypes: ['Counter'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API,
    prepareHeaders: prepareHeaders,
  }),
  reducerPath: 'counterReducer',
  endpoints: (build) => ({
    getLastReadings: build.query<IReadings[], number>({
      query: (idDevice) => routeRoot.concat(`readingLast/${idDevice}`),
      keepUnusedDataFor: 120,
      providesTags: ['Counter']
    }),
    getLastReadingsTime: build.query<IReadings, number>({
      query: (idDevice) => routeRoot.concat(`readingLastTime/${idDevice}`),
      keepUnusedDataFor: 120,
      providesTags: ['Counter']
    }),
    getStore: build.query<IStore, number>({
      query: (storeId) => routeRoot.concat(`store/${storeId}`),
      keepUnusedDataFor: 120,
      providesTags: ['Counter']
    }),
    getDevice: build.query<IDevice, number>({
      query: (deviceId) => routeRoot.concat(`device/${deviceId}`),
      keepUnusedDataFor: 120,
      providesTags: ['Counter']
    }),
    getAllCounterByDeviceId: build.query<ICounters[], number>({
      query: (deviceId) => routeRoot.concat(`counter/all/${deviceId}`),
      keepUnusedDataFor: 120,
      providesTags: ['Counter']
    }),
    getAllActiveCounterByDeviceId: build.query<ICounters[], number>({
      query: (deviceId) => routeRoot.concat(`counter/active/${deviceId}`),
      keepUnusedDataFor: 120,
      providesTags: ['Counter']
    }),
    manageCounter: build.mutation<any, { body: Partial<ICounters>  }>({
      query: ({ body }) => ({
        url: routeRoot.concat(body.id?.toString() || ''),
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: any) => response.data,
      invalidatesTags: ['Counter'],
    })
  }),
});

export default counterApi;

export const {
  useGetLastReadingsQuery,
  useGetLastReadingsTimeQuery,
  useGetStoreQuery,
  useGetDeviceQuery,
  useGetAllActiveCounterByDeviceIdQuery,
  useGetAllCounterByDeviceIdQuery,
  useManageCounterMutation
} = counterApi;