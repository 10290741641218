import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/rootReducer";
import { ThemeInfo, updateTheme, resetTheme } from "../store/slices/theme";

const useThemeInfo = () => {
  const themeInfo = useSelector<RootState, ThemeInfo>((state) => state.theme);
  const dispatch = useDispatch();

  return {
    themeInfo,
    update: (payload: Partial<ThemeInfo>) => dispatch(updateTheme(payload))
  }
};

export default useThemeInfo;