import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BASE_API } from "services/config";

import prepareHeaders from "./common";

const routeRoot = 'theme/';

export interface ITheme {
  id: number;
  name: string;
  description: string;
  logo: string;
  primary: string;
  primaryColor: string;
  secondary: string;
  secondaryColor: string;
  enable: boolean;
  [key: string]: any;
}


const themeInfoApi = createApi({
  tagTypes: ['Theme', 'Dashboard'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API,
    prepareHeaders: prepareHeaders,
  }),
  reducerPath: 'themeReducer',
  endpoints: (build) => ({
    getAllTheme: build.query<ITheme[], void>({
      query: () => routeRoot,
      keepUnusedDataFor: 120,
      providesTags: ['Theme']
    }),
    addTheme: build.mutation<any, { body: Partial<ITheme> }>({
      query: ({ body }) => ({
        url: routeRoot,
        method: 'POST',
        body
      }),
      transformResponse: (response: any) => response.data,
      invalidatesTags: ['Theme','Dashboard'],
    }),
    updateTheme: build.mutation<any, { body: Partial<ITheme>  }>({
      query: ({ body }) => ({
        url: routeRoot.concat(body.id?.toString() || ''),
        method: 'PATCH',
        body
      }),
      transformResponse: (response: any) => response.data,
      invalidatesTags: ['Theme', 'Dashboard'],
    }),
    deleteTheme: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: routeRoot.concat(id.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: ['Theme','Dashboard'],
    })
  }),
});

export default themeInfoApi;

export const {
  useGetAllThemeQuery,
  useAddThemeMutation,
  useUpdateThemeMutation,
  useDeleteThemeMutation,
} = themeInfoApi;