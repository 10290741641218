import { lazy } from "react";

import PrivateRoute from "./PrivateRoute";
import Layout from "../modules/layout";

const PATHS = {
  HOME: '',
  LOGIN: 'login',
  PROFILE: 'profile',
  SETTINGS: 'settings',
  MANAGEMENT: 'management',
  ALARMS: 'alarms',
  COUNTERS: 'counters',
  COMMANDS: 'commands',
  STATISTICS: 'statistics'
} as const;

const Home = lazy(() => import('../modules/home'));
const Login = lazy(() => import('../modules/login'));
const Profile = lazy(() => import('../modules/profile'));
const Settings = lazy(() => import('../modules/settings'));
const Management = lazy(() => import('../modules/management'));
const Alarms = lazy(() => import('../modules/alarms'));
const Counters = lazy(() => import('../modules/counters'));
const Commands = lazy(() => import('../modules/commands'));
const Statistics = lazy(() => import('../modules/statistics'));

const paths = [
  {
    key: 'Home',
    path: PATHS.HOME,
    element: (<PrivateRoute>
      <Layout>
        <Home />
      </Layout>
    </PrivateRoute>),
  },
  {
    key: 'Profile',
    path: PATHS.PROFILE,
    element: (<PrivateRoute>
      <Layout>
        <Profile />
      </Layout>
    </PrivateRoute>),
  },
  {
    key: 'Settings',
    path: PATHS.SETTINGS,
    element: (<PrivateRoute>
      <Layout>
        <Settings />
      </Layout>
    </PrivateRoute>),
  },
  {
    key: 'Management',
    path: `${PATHS.MANAGEMENT}/*`,
    element: (<PrivateRoute>
      <Layout>
        <Management />
      </Layout>
    </PrivateRoute>),
  },
  {
    key: 'Alarms',
    path: `${PATHS.ALARMS}/*`,
    element: (<PrivateRoute>
      <Layout>
        <Alarms />
      </Layout>
    </PrivateRoute>),
  },
  {
    key: 'Counters',
    path: `${PATHS.COUNTERS}/*`,
    element: (<PrivateRoute>
      <Layout>
        <Counters />
      </Layout>
    </PrivateRoute>),
  },
  {
    key: 'Commands',
    path: `${PATHS.COMMANDS}/*`,
    element: (<PrivateRoute>
      <Layout>
        <Commands />
      </Layout>
    </PrivateRoute>),
  },
  {
    key: 'Statistics',
    path: `${PATHS.STATISTICS}/*`,
    element: (<PrivateRoute>
      <Layout>
        <Statistics />
      </Layout>
    </PrivateRoute>),
  },
  {
    key: 'Login',
    path: PATHS.LOGIN,
    element: <Login />,
  },
  {
    key: 'NotFound',
    path: '*',
    element: <p> NOT </p>,
  }
];

export default paths;