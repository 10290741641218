import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from "@material-ui/core";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";

import './index.css';
import App from './App';

import theme from "./theme";

import { store } from "./store";

import TranslationProvider from "./translations";

ReactDOM.render(
    <Provider store={store}>
      {/* <ThemeProvider theme={theme}> */}
        <TranslationProvider>
          <BrowserRouter>
              <App />
              <Toaster
                position="top-right"
                toastOptions={{
                  duration: 2000,
                  style: {
                    background: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    boxShadow: theme.shadows[4],
                  }
                }}
                containerStyle={{
                  marginTop: '64px',
                  zIndex: 1300,
                }}
              />
          </BrowserRouter>
        </TranslationProvider>
      {/* </ThemeProvider> */}
    </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
