import { CircularProgress, ThemeProvider } from '@material-ui/core';
import { Suspense } from 'react';
import { Routes, Route } from "react-router-dom";
import paths from "./routes/index";
import { ConfirmProvider } from "material-ui-confirm";

import theme from "./theme";
import useUserInfo from "hooks/useUserInfo";

import { createTheme } from "@material-ui/core";
import useThemeInfo from 'hooks/useThemeInfo';

function App() {

  
  const {themeInfo} = useThemeInfo();
  //console.log(themeInfo);

  let primary = '#56d600'
  let primaryColor = '#000'
  let secondary = '#56d600'
  let secondaryColor = '#000'
  if (themeInfo.id != 0 && themeInfo.primary != '')
  {
    primary = themeInfo.primary;
    primaryColor = themeInfo.primaryColor
    secondary = themeInfo.secondary;
    secondaryColor = themeInfo.secondaryColor;
  }
  

  //console.log(themeInfo)
  //const primary = '#f5f5f5'; 
  
  let theme = createTheme({
    palette: {
      primary: {
        // light: '#757ce8',
        main: primary,
        // dark: '#002884',
        contrastText: primaryColor,
      },
      secondary: {
        // light: '#ff7961',
        main: secondary,
        // dark: '#ba000d',
        contrastText: secondaryColor,
      },
    },
  });

return (
  <ThemeProvider theme={theme}>
    <Suspense fallback={<p style={{ textAlign: "center" }}> <CircularProgress /> </p>}>
      <ConfirmProvider>
        <Routes>
          {paths.map((props) => {
            return <Route {...props} />;
          })}
        </Routes>
      </ConfirmProvider>
    </Suspense>
    </ThemeProvider>
  );
}

export default App;
