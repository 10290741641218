import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  userInfoApi,
  storeApi,
  deviceApi,
  deviceTypesApi,
  deviceTypeDetailsApi,
  dashboardApi,
  userStoreApi,
  storeDeviceApi,
  deviceTypeCommandsApi,
  alarmApi,
  commandApi,
  counterApi,
  themeApi

} from 'api';

import rootReducer from "./rootReducer";

export const store = configureStore({
  reducer: rootReducer,
  // @ts-ignore
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userInfoApi.middleware)
      .concat(storeApi.middleware)
      .concat(deviceApi.middleware)
      .concat(deviceTypesApi.middleware)
      .concat(deviceTypeDetailsApi.middleware)
      .concat(dashboardApi.middleware)
      .concat(userStoreApi.middleware)
      .concat(storeDeviceApi.middleware)
      .concat(alarmApi.middleware)
      .concat(deviceTypeCommandsApi.middleware)
      .concat(counterApi.middleware)
      .concat(commandApi.middleware)
      .concat(themeApi.middleware)
});

setupListeners(store.dispatch);