import { Card, styled } from "@material-ui/core";

const CustomCard = styled(Card)(({ theme }) => {
  const grad = `linear-gradient(white, white),
  linear-gradient(${theme.palette.secondary.main}, lightgray)`;
  return {
    border: '1px solid transparent',
    borderRadius: '4px',
    backgroundImage: grad,
    backgroundOrigin: 'border-box, border-box',
    backgroundClip: 'padding-box, border-box',
  }
});

export default CustomCard;