import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BASE_API } from "services/config";

import prepareHeaders from "./common";
import { AnyAction } from "redux";

const routeRoot = 'dashboard/';

export interface IStore {
  id: number;
  uuid: string;
  name: string;
  address: string;
}

export interface IDevice {
  id: number;
  uuid: string;
  name: string;
  ipAddress: string;
  macAddress: string;
  latitude: string;
  longitude: string;
  notes: string;
  mqttTopic?: string;
  deviceType?: number;
  serialNumber?: string;
  userNotes?: string;
  enableSubscription?: boolean;
  subscriptionStart?: Date;
  subscriptionEnd?: Date;
  subscriptionValue?: string;
}

export interface IReadings {
  id: number;
  idStore: number;
  dataName: string;
  dataValue: number;
  readingAt: Date;
}

export interface IAlarms {
  id: number;
  idDevice: number;
  dataField: string;
  dataValue: number;
  active: boolean;
  alarmDescr: string;
  alarmTreshold: number;
  createdOn: Date;
  updatedOn: Date;
  managed: boolean;
}

export interface ICounters {
  id: number;
  idDevice: number;
  dataField: string;
  counterValue: number;
  createdOn: Date;
  updatedOn: Date;
  reset: boolean;
  active: boolean;
}

export interface ICycleCounters {
  id: number;
  idDevice: number;
  dataField: string;
  counterValue: number;
  counterTotalValue: number;
  createdOn: Date;
  updatedOn: Date;
  reset: boolean;
}

const dashboardApi = createApi({
  tagTypes: ['Dashboard'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API,
    prepareHeaders: prepareHeaders,
  }),
  reducerPath: 'dashboardReducer',
  endpoints: (build) => ({
    getLastReadings: build.query<IReadings[], number>({
      query: (idDevice) => routeRoot.concat(`readingLast/${idDevice}`),
      keepUnusedDataFor: 120,
      providesTags: ['Dashboard']
    }),
    getLastReadingsTime: build.query<IReadings, number>({
      query: (idDevice) => routeRoot.concat(`readingLastTime/${idDevice}`),
      keepUnusedDataFor: 120,
      providesTags: ['Dashboard']
    }),
    getStore: build.query<IStore, number>({
      query: (storeId) => routeRoot.concat(`store/${storeId}`),
      keepUnusedDataFor: 120,
      providesTags: ['Dashboard']
    }),
    getDevice: build.query<IDevice, number>({
      query: (deviceId) => routeRoot.concat(`device/${deviceId}`),
      keepUnusedDataFor: 120,
      providesTags: ['Dashboard']
    }),
    getAlarm: build.query<IAlarms, number>({
      query: (deviceId) => routeRoot.concat(`alarm/${deviceId}`),
      keepUnusedDataFor: 120,
      providesTags: ['Dashboard']
    }),
    getAllAlarmByDeviceId: build.query<IAlarms[], number>({
      query: (deviceId) => routeRoot.concat(`alarm/all/${deviceId}`),
      keepUnusedDataFor: 120,
      providesTags: ['Dashboard']
    }),
    getAllCounterByDeviceId: build.query<ICounters[], number>({
      query: (deviceId) => routeRoot.concat(`counters/${deviceId}`),
      keepUnusedDataFor: 120,
      providesTags: ['Dashboard']
    }),
    getAllActiveCounterByDeviceId: build.query<ICounters[], number>({
      query: (deviceId) => routeRoot.concat(`counters/active/${deviceId}`),
      keepUnusedDataFor: 120,
      providesTags: ['Dashboard']
    }),
    getCycleCounterByDeviceId: build.query<ICycleCounters, number>({
      query: (deviceId) => routeRoot.concat(`cycleCounters/${deviceId}`),
      keepUnusedDataFor: 120,
      providesTags: ['Dashboard']
    }),
    resetCycleCounterByDeviceId:  build.mutation<any, { body: {deviceId: number, field: string} }>({
      query: ({body}) => routeRoot.concat(`cycleCounters/reset/${body.deviceId}/${body.field}/`),
      transformResponse: (response: any) => response.data,
      invalidatesTags: ['Dashboard']
    }),
    updateCounter: build.mutation<any, { body: Partial<ICounters>  }>({
      query: ({ body }) => ({
        url: routeRoot.concat(body.id?.toString() || ''),
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: any) => response.data,
      invalidatesTags: ['Dashboard'],
    }),
    updateDeviceSerialNotes: build.mutation<any, { body: Partial<IDevice>  }>({
      query: ({ body }) => ({
        url: routeRoot.concat(`/device/`,body.id?.toString() || ''),
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: any) => response.data,
      invalidatesTags: ['Dashboard'],
    })
  }),
});

export default dashboardApi;

export const {
  useGetLastReadingsQuery,
  useGetLastReadingsTimeQuery,
  useGetStoreQuery,
  useGetDeviceQuery,
  useGetAlarmQuery,
  useGetAllAlarmByDeviceIdQuery,
  useGetAllCounterByDeviceIdQuery,
  useGetAllActiveCounterByDeviceIdQuery,
  useGetCycleCounterByDeviceIdQuery,
  useResetCycleCounterByDeviceIdMutation,
  useUpdateCounterMutation,
  useUpdateDeviceSerialNotesMutation
} = dashboardApi;