import { toggle_off, toggle_on } from '../assets/on_off';

const Toggle_On = () => {
  return <img style={{ minWidth: '30px', maxWidth: '50px' }} alt='On' src={toggle_on} />
}
const Toggle_Off = () => {
  return <img style={{ minWidth: '30px', maxWidth: '50px' }} alt='Off' src={toggle_off} />
}

export {
  Toggle_On,
  Toggle_Off
};