import { FC } from "react";
import {
  styled,
  alpha,
  Box,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { DeleteForever, Edit } from "@material-ui/icons";

interface DataCellProps {
  data: {
    title: string;
    body: string;
    subBody?: string;
  };
  onEdit?: () => void;
  onDelete?: () => void;
}

const UserSection = styled('div')(({ theme }) => {
  const border = `1px solid ${alpha(theme.palette.secondary.main, .5)}`
  return {
    padding: theme.spacing(1),
    borderTop: border,
    "&:last-child": {
      borderBottom: border,
    }
  }
});


const DataCell: FC<DataCellProps> = ({
  data,
  onEdit = () => {},
  onDelete = () => {},
}) => {

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <UserSection>
      <Box
        display="flex"
        flexDirection={smallScreen ? "row" : "row" }
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Typography
            variant="subtitle2"
          >
            {data.title}
          </Typography>
          <Typography
              variant="caption"
              className="role"
              color="secondary"
              style={{
                lineHeight: '28px',
              }}
            >
              {data.body}
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
            >
              {data.subBody || ''}
            </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <IconButton
            onClick={onEdit}
          >
            <Edit color="secondary" />
          </IconButton>
          <IconButton> 
            <DeleteForever
              onClick={onDelete}
              color="error"
            />
          </IconButton>
        </Box>
      </Box>
    </UserSection>
  );

};

export default DataCell;