import { merge } from "ramda";
import { createSlice, SliceCaseReducers, PayloadAction } from "@reduxjs/toolkit";
import userManager from "../../services/userManager";


export interface UserInfo {
  username: string;
  email: string;
  role: string;
  token: string;
  expiresIn: number;
  idTheme: number;
}


const slice = createSlice<UserInfo, SliceCaseReducers<UserInfo>>({
  name: 'user',
  initialState: {
    username: userManager.getData().username || '',
    email: userManager.getData().email || '',
    role:  userManager.getData().role || '',
    token: userManager.getData().token || '',
    expiresIn: Number(userManager.getData().expiresIn) || 0,
    idTheme:   Number(userManager.getData().idTheme) || 0,
  },
  reducers: {
    updateUser: (state, action: PayloadAction<UserInfo>) => merge(state, action.payload),
    resetUser: (state) => merge(state, { username:'', email: '', role: '', token: '', expiresIn: 0 }),
  },
});

export default slice.reducer;

export const { updateUser, resetUser } = slice.actions;