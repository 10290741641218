import { merge } from "ramda";
import { createSlice, SliceCaseReducers, PayloadAction } from "@reduxjs/toolkit";
import userManager from "services/userManager";

export interface ThemeInfo {
  id: number;
  name: string;
  description: string;
  logo: string;
  primary: string;
  primaryColor: string;
  secondary: string;
  secondaryColor: string;
}


const slice = createSlice<ThemeInfo, SliceCaseReducers<ThemeInfo>>({
  name: 'theme',
  initialState: {
    id: Number(userManager.getThemeData().id) || 0,
    name: userManager.getThemeData().name || '',
    description: userManager.getThemeData().description || '',
    logo: userManager.getThemeData().logo || '',
    primary: userManager.getThemeData().primary || '',
    primaryColor: userManager.getThemeData().primaryColor || '',
    secondary: userManager.getThemeData().secondary || '',
    secondaryColor: userManager.getThemeData().secondaryColor || '',
  },
  reducers: {
    updateTheme: (state, action: PayloadAction<ThemeInfo>) => merge(state, action.payload),
    resetTheme: (state) => merge(state, { id: 0,
      name: '',
      description: '',
      logo: '',
      primary: '',
      primaryColor: '',
      secondary: '',
      secondaryColor: ''}),
  },
});

export default slice.reducer;

export const { updateTheme, resetTheme } = slice.actions;