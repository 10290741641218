class UserManager {
  localStorage: Storage;
  keys = {
    token: 'token',
    expires: 'exp',
    username: 'username',
    email: 'email',
    role: 'role',
    idTheme: 'idTheme',
    themeId: 'themeId',
    name: 'name',
    description: 'description',
    logo: 'logo',
    primary: 'primary',
    primaryColor: 'primaryColor',
    secondary: 'secondary',
    secondaryColor: 'secondaryColor'
  };
  constructor(localStorage: Storage) {
    this.localStorage = localStorage;
  }

  saveData = (username: string,  email: string, role: string, token: string, expiry: number, idTheme: number) => {
    this.localStorage.setItem(this.keys.username, username);
    this.localStorage.setItem(this.keys.email, email);
    this.localStorage.setItem(this.keys.role, role);
    this.localStorage.setItem(this.keys.token, token);
    this.localStorage.setItem(this.keys.expires, `${expiry}`);
    this.localStorage.setItem(this.keys.idTheme, `${idTheme}`);
  }

  saveThemeData = (id: string, name: string, description:string, logo: string, primary: string, primaryColor: string, secondary: string, secondaryColor: string) => {
    this.localStorage.setItem(this.keys.themeId, id);
    this.localStorage.setItem(this.keys.name, name);
    this.localStorage.setItem(this.keys.description, description);
    this.localStorage.setItem(this.keys.logo, logo);
    this.localStorage.setItem(this.keys.primary, primary);
    this.localStorage.setItem(this.keys.primaryColor, primaryColor);
    this.localStorage.setItem(this.keys.secondary, secondary);
    this.localStorage.setItem(this.keys.secondaryColor, secondaryColor);
  }


  getData = () => {
    return {
      token: this.localStorage.getItem(this.keys.token),
      expiresIn: this.localStorage.getItem(this.keys.expires),      
      username: this.localStorage.getItem(this.keys.username),
      email: this.localStorage.getItem(this.keys.email),
      role: this.localStorage.getItem(this.keys.role),
      idTheme: this.localStorage.getItem(this.keys.idTheme),
    }
  }

  getThemeData = () => {
    return {
      id: this.localStorage.getItem(this.keys.themeId),
      name: this.localStorage.getItem(this.keys.name),
      description: this.localStorage.getItem(this.keys.description),
      logo: this.localStorage.getItem(this.keys.logo),
      primary: this.localStorage.getItem(this.keys.primary),
      primaryColor: this.localStorage.getItem(this.keys.primaryColor),
      secondary: this.localStorage.getItem(this.keys.secondary),
      secondaryColor: this.localStorage.getItem(this.keys.secondaryColor)
    }
  }

  logout = () => {
    this.localStorage.removeItem(this.keys.expires);
    this.localStorage.removeItem(this.keys.token);
    this.localStorage.removeItem(this.keys.username);
    this.localStorage.removeItem(this.keys.email);
    this.localStorage.removeItem(this.keys.role);
    this.localStorage.removeItem(this.keys.idTheme);
    this.localStorage.removeItem(this.keys.name);
    this.localStorage.removeItem(this.keys.description);
    this.localStorage.removeItem(this.keys.logo);
    this.localStorage.removeItem(this.keys.primary);
    this.localStorage.removeItem(this.keys.primaryColor);
    this.localStorage.removeItem(this.keys.secondary);
    this.localStorage.removeItem(this.keys.secondaryColor);
    this.localStorage.removeItem(this.keys.themeId);
  }

};

const userManager = new UserManager(localStorage);

export default userManager;