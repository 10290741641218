import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BASE_API } from "services/config";

import prepareHeaders from "./common";

const routeRoot = 'devices/';

// interface IDeviceInfo {
//   bitId: number;
//   name: string;
//   status?: 'ONLINE' | 'OFFLINE';
// };

export interface IDevice {
  id: number;
  uuid: string;
  name: string;
  ipAddress: string;
  macAddress: string;
  latitude: string;
  longitude: string;
  notes: string;
  mqttTopic?: string;
  deviceType?: number;
  serialNumber?: string;
  userNotes?: string;
  enableSubscription?: boolean;
  subscriptionStart?: Date;
  subscriptionEnd?: Date;
  subscriptionValue?: string;
}

const deviceInfoApi = createApi({
  tagTypes: ['Device','Dashboard'],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API,
    prepareHeaders: prepareHeaders,
  }),
  reducerPath: 'deviceReducer',
  endpoints: (build) => ({
    getAllDevices: build.query<IDevice[], void>({
      query: () => routeRoot,
      keepUnusedDataFor: 120,
      providesTags: ['Device','Dashboard']
    }),
    addDevice: build.mutation<any, { body: Partial<IDevice> }>({
      query: ({ body }) => ({
        url: routeRoot,
        method: 'POST',
        body
      }),
      transformResponse: (response: any) => response.data,
      invalidatesTags: ['Device','Dashboard'],
    }),
    updateDevice: build.mutation<any, { body: Partial<IDevice>  }>({
      query: ({ body }) => ({
        url: routeRoot.concat(body.id?.toString() || ''),
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: any) => response.data,
      invalidatesTags: ['Device','Dashboard'],
    }),
    deleteDevice: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: routeRoot.concat(id.toString()),
        method: 'DELETE',
      }),
      invalidatesTags: ['Device','Dashboard'],
    })
  }),
});

export default deviceInfoApi;

export const {
  useGetAllDevicesQuery,
  useAddDeviceMutation,
  useDeleteDeviceMutation,
  useUpdateDeviceMutation,
} = deviceInfoApi;