import { FC } from "react";
import {
  styled,
  alpha,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { 
  Battery_0,
  Battery_25,
  Battery_50,
  Battery_75,
  Battery_100,
  Battery_Error
} from "./Battery";

import { Alarm } from "./Alarm";
import { Counter } from "./Counter";

import { Toggle_On,Toggle_Off } from "./toggle";
import { useEffect, useState } from "react";
import { ICounters, useResetCycleCounterByDeviceIdMutation } from "api/dashboardApi";
import toast from "react-hot-toast";
import useFormattedMessage from "translations/useFormattedMessage";
import { useGetUserInfoQuery } from "api/userApi";

interface ReadingCellProps {
  data: {
    SensorId: number;
    SensorName: string;
    DataValue?: string;
    DataUM?:string;
    DataIsBattery?: boolean;
    BatteryValue?: number;
    BatteryState?: number;
    AlarmActive?: boolean;
    CounterActive?: boolean;
    DataIsOnOff?: boolean;
    enableCycleCounter?: boolean;
    CycleCounter?: number;
    CycleTotalCounter?: number;
    DataField: string;
    idDevice: number;
  };
  onEdit?: () => void;
  onDelete?: () => void;
}

const ReadingSection = styled('div')(({ theme }) => {
  const border = `1px solid ${alpha(theme.palette.secondary.main, .5)}`
  return {
    padding: theme.spacing(1),
    borderTop: border,
    "&:last-child": {
      borderBottom: border,
    }
  }
});

const ReadingCell: FC<ReadingCellProps> = ({
  data,
}) => {

  const { data: userData } = useGetUserInfoQuery();
  const [ updateCounter ] = useResetCycleCounterByDeviceIdMutation();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const t = useFormattedMessage({ prefix: 'reading.' });
    const [open, setOpen] = useState({dialogState: false, idSensor:0, fieldName: ''});
  
  const openDialog = (id: number,  field: string) => {
    console.log(id,field);
    if (userData?.role == 'ADMIN' || userData?.role  === 'SUPERADMIN'  || userData?.role === 'SUPERUSER')
      setOpen({dialogState: true, idSensor:id, fieldName:field});
  };
  
  const handleClose = () => {
    setOpen({dialogState: false, idSensor:0, fieldName:''});
  };
  
  //console.log(data);

  return (
    <ReadingSection>
      <Box
        display="flex"
        flexDirection={smallScreen ? "row" : "row" }
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Typography
            variant="h5"
            style={{
              lineHeight: '28px',
            }}
          >
            {data.SensorName}&nbsp;
            {/* <Typography
            variant="caption"
            color="primary"
            style={{
              lineHeight: '28px',
            }}
          >
             ({data.sensorMacAddres})
          </Typography> */}
          </Typography>
        </Box>
       
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Box
            display="flex"
            flexDirection={smallScreen ? "row" : "row" }
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              {data.DataIsBattery ? 
              (<p style={{ textAlign: "right" }}> <Typography
              variant="h5"
              className="role"
              color="secondary"
              style={{
                lineHeight: '28px',
                textAlign: 'right'
              }}> {data.BatteryValue} {data.DataUM}</Typography> </p> ) : data.enableCycleCounter? 
               (
               <><Typography
              variant="h5"
              className="role"
              color="secondary"
              style={{
                lineHeight: '28px',
                textAlign: 'right'
              }}
              onClick={() => { console.log("ASD"); openDialog(data.idDevice, data.DataField)}}
              >  {data.CycleCounter} </Typography><Typography
              variant="body2"
              className="role"
              color="secondary"
              style={{
                lineHeight: '28px',
                textAlign: 'right'
              }}>  / {data.CycleTotalCounter} </Typography></>
              ) 
              
              : data.DataIsOnOff? {
                1: <Toggle_On />,
                0: <Toggle_Off />,
              }[data.DataValue ?? 0] : <Typography
              variant="h5"
              className="role"
              color="secondary"
              style={{
                lineHeight: '28px',
                textAlign: 'right'
              }}>  {data.DataValue} </Typography>}
                
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Typography
                variant="h5"
                className="role"
                color="secondary"
                style={{
                  lineHeight: '28px',
                  width: '40px',
                  textAlign: 'left',
                  marginLeft: '10px'
                }}
              >
                 {data.DataIsBattery ? 
                    {
                      5: <Battery_100 />,
                      4: <Battery_75 />,
                      3: <Battery_50 />,
                      2: <Battery_25 />,
                      1: <Battery_0 />,
                      0: <Battery_Error />,
                    }[data.BatteryState ?? 0]
              : data.DataIsOnOff? null : data.DataUM}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-end"
            >
              <Typography
                variant="h5"
                className="role"
                color="secondary"
                style={{
                  lineHeight: '28px',
                  width: '40px',
                  textAlign: 'right',
                  marginLeft: '10px'
                }}
              >
                 {data.AlarmActive ? 
                <Alarm/>
                : null}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-end"
            >
              <Typography
                variant="h5"
                className="role"
                color="secondary"
                style={{
                  lineHeight: '28px',
                  width: '40px',
                  textAlign: 'right',
                  marginLeft: '10px'
                }}
              >
                 {data.CounterActive ? 
                <Counter/>
                : null}
              </Typography>
            </Box>
          </Box>
        </Box>
       
      </Box>
      <Dialog open={open.dialogState} onClose={handleClose}>
        <DialogTitle>{t("ResetChangeCounterValue")}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>{t("Cancel")}</Button>
          <Button onClick={() => { 
            console.log(open.fieldName);
            toast.promise(
              updateCounter({body: { deviceId: open.idSensor, field: open.fieldName }}).unwrap(),
              {
                error: `${t("deleteDeviceError")}`,
                success: `${t("counter")} ${open.fieldName} ${t("edited")}`,
                loading: `${t("loading")}`
              }
            )
            handleClose();
            }}> {t("Save")}</Button>
        </DialogActions>
      </Dialog>
    </ReadingSection>
    
  );

};

export default ReadingCell;