import { merge } from "ramda";
import { createSlice, SliceCaseReducers, PayloadAction } from "@reduxjs/toolkit";

import { Langs } from "../../translations/constants";
import Cookies from "universal-cookie";

export interface LanguageInfo {
  language: Langs;
}
const cookies = new Cookies();

const slice = createSlice<LanguageInfo, SliceCaseReducers<LanguageInfo>>({
  name: 'language',
  initialState: {
    language: cookies.get('selectedLanguage') ?? Langs.EN,
  },
  reducers: {
    setLanguage: (state, action: PayloadAction<LanguageInfo>) => merge(state, { language: action.payload.language }),
  },
});

export default slice.reducer;

export const { setLanguage } = slice.actions;