import { combineReducers } from "redux";
import { userInfoApi, storeApi, deviceApi, dashboardApi, userStoreApi, storeDeviceApi, deviceTypesApi, deviceTypeDetailsApi, alarmApi, deviceTypeCommandsApi, commandApi, themeApi, statisticsApi } from 'api';

import userReducer, { UserInfo } from "./slices/user";
import languageReducer, { LanguageInfo } from "./slices/language";
import themeReducer, {  ThemeInfo } from "./slices/theme";

export interface RootState {
  user: UserInfo;
  language: LanguageInfo;
  theme: ThemeInfo
};

const rootReducer = combineReducers({
  user: userReducer,
  language: languageReducer,
  theme: themeReducer,
  [userInfoApi.reducerPath]: userInfoApi.reducer,
  [storeApi.reducerPath]: storeApi.reducer,
  [deviceApi.reducerPath]: deviceApi.reducer,
  [deviceTypesApi.reducerPath]: deviceTypesApi.reducer,
  [deviceTypeDetailsApi.reducerPath]: deviceTypeDetailsApi.reducer,
  [deviceTypeCommandsApi.reducerPath]: deviceTypeCommandsApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,
  [userStoreApi.reducerPath]: userStoreApi.reducer,
  [storeDeviceApi.reducerPath]: storeDeviceApi.reducer,  
  [alarmApi.reducerPath]: alarmApi.reducer, 
  [commandApi.reducerPath]: commandApi.reducer,
  [themeApi.reducerPath]: themeApi.reducer,
  [statisticsApi.reducerPath]: statisticsApi.reducer
});

export default rootReducer;